import React from 'react'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import { Pagination, PaginationItem } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        }
    },
}));

const Pager = ({ pageContext }) => {
    const { numberOfPages, pageNumber, slug } = pageContext;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Pagination
                count={numberOfPages}
                page={pageNumber + 1}
                renderItem={item => (
                    <PaginationItem
                        component={Link}
                        to={`/categorias/${slug}${item.page === 1 ? '' : `/${item.page}`}`}
                        {...item}
                    />
                )}
            />
        </div>
    )
}

export default Pager